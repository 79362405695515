import React, { Fragment, useMemo } from 'react';
import { graphql } from 'gatsby';
import 'intersection-observer';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';

import { AsSeenIn, Faq, Text, TextWithImage } from '../components/MobileAppPage';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

const MobileApp = ({ data, location }) => {
  const bgImageData = useMemo(() => data?.bgImages?.edges || [], [data.bgImages]);
  const pageImageData = useMemo(() => data?.pageImages?.edges || [], [data.pageImages]);

  /* const heroSection = useMemo(
    () => (
      <HeroSection
        pageImages={pageImageData}
        sectionData={data.contentfulPage.contentModules.find(
          m => m.__typename === 'ContentfulMobileAppPageHeroSection'
        )}
      />
    ),
    [pageImageData, data.contentfulPage.contentModules]
  ); */
  const logoSection = useMemo(
    () => (
      <AsSeenIn
        sectionData={data.contentfulPage.contentModules.find(
          m => m.__typename === 'ContentfulLogoSection'
        )}
      />
    ),
    [data.contentfulPage.contentModules]
  );
  const textWithImageSection = useMemo(
    () => (
      <TextWithImage
        bgImages={bgImageData}
        pageImages={pageImageData}
        sectionData={data.contentfulPage.contentModules.find(
          m => m.__typename === 'ContentfulMobileAppPageTextWithImageSection'
        )}
      />
    ),
    [bgImageData, pageImageData, data.contentfulPage.contentModules]
  );
  const textSection = useMemo(
    () => (
      <Text
        bgImages={bgImageData}
        pageImages={pageImageData}
        sectionData={data.contentfulPage.contentModules.find(
          m => m.__typename === 'ContentfulMobileAppPageTextSection'
        )}
      />
    ),
    [bgImageData, pageImageData, data.contentfulPage.contentModules]
  );
  const faqSection = useMemo(
    () => (
      <Faq
        bgImages={bgImageData}
        sectionData={data.contentfulPage.contentModules.find(
          m => m.__typename === 'ContentfulFaqSection'
        )}
      />
    ),
    [bgImageData, data.contentfulPage.contentModules]
  );
  return (
    <Fragment>
      <MailingListPopup />
      <SEO title="Mobile App" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div className="mobile-app">
        {textWithImageSection}
        {/* {heroSection} */}
        {logoSection}
        {faqSection}
        {textSection}
      </div>
    </Fragment>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "mobile-app" }) {
      contentModules {
        ... on ContentfulMobileAppPageHeroSection {
          heading
          backgroundImage {
            fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            description
          }
          appStoreLink
          googlePlayLink
          mobilePreview {
            description
            file {
              contentType
              url
            }
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulMobileAppPageTextWithImageSection {
          title
          appStoreLink
          googlePlayLink
          backgroundImage {
            fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          textWithImageBlock {
            title
            heading
            description {
              description
            }
            mobilePreview {
              description
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulMobileAppPageTextSection {
          heading
          appStoreLink
          googlePlayLink
        }
        ... on ContentfulLogoSection {
          heading
          title
          logoBlock {
            url
            title
            image {
              description
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulFaqSection {
          heading
          faqSectionFaqBlock {
            question {
              question
            }
            answer {
              answer
            }
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    pageImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/mobileApp/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default MobileApp;
