import React from 'react';
import Image from 'gatsby-image';
import { BackgroundDivBgImage, CenteredContainer } from '../../.base/containers';
import { breakpoint } from '../../../constants/Breakpoint';
import { getImage } from '../../../helpers';
import { Heading, HeadingLarge, Subheading } from '../../.base/headings';
import styled, { css } from 'styled-components';
import { WoodenBorder } from '../../.base/border';
import { Link } from 'gatsby';

const StoreWrapper = styled.a`
    padding: 23px 49px 23px 30px;
    border-radius: 20px;
    background-color: #1d1d1d;
    display: grid;
    grid-template-columns: 37px auto;
    grid-column-gap: 30px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
    }
    img {
        width: 100%;
    }
    ${Subheading}, ${Heading} {
        color: #fff;
        text-align: left;
        margin-bottom: 0;
    }
`;

const Text = ({ bgImages, pageImages, sectionData }) => {
    const { appStoreLink, googlePlayLink, heading } = sectionData;
    return (
        <CenteredContainer
            css={css`
                padding: 60px 20px;
                position: relative;
                @media ${breakpoint.lg} {
                    padding: 170px 20px;
                }
            `}
        >
            <div
                css={css`
                    display: none;
                    width: 100%;
                    @media ${breakpoint.lg} {
                        display: block;
                    }
                `}
            >
                <BackgroundDivBgImage
                    w="100%"
                    h="100%"
                    heightMobile="100%"
                    left="0"
                    top="0"
                    topMobile="0"
                    color="#edf2f2"
                    style={{ zIndex: '-2' }}
                />
                <WoodenBorder top="0" />
            </div>
            <HeadingLarge
                as="h2"
                css={css`
                    margin-bottom: 25px;
                `}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: heading,
                    }}
                ></span>
            </HeadingLarge>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 10px;
                    @media ${breakpoint.md} {
                        grid-template-columns: 1fr 1fr;
                    }
                `}
            >
                <StoreWrapper as={Link} href={appStoreLink}>
                    <div>
                        <Image fluid={getImage(pageImages, 'apple-logo')} alt="App Store" />
                    </div>
                    <div>
                        <Subheading as="p">DOWNLOAD ON THE</Subheading>
                        <Heading as="p">App Store</Heading>
                    </div>
                </StoreWrapper>
                <StoreWrapper as={Link} href={googlePlayLink}>
                    <div>
                        <Image fluid={getImage(pageImages, 'play-store-logo')} alt="Play Store" />
                    </div>
                    <div>
                        <Subheading as="p">Get it on</Subheading>
                        <Heading as="p">Google Play</Heading>
                    </div>
                </StoreWrapper>
            </div>
        </CenteredContainer>
    );
};

export default Text;
