import styled from 'styled-components';
import { breakpoint } from '~/constants/Breakpoint';
import { Heading, Subheading } from '~/components/.base/headings';

export const HeroCol = styled.div`
  width: 293px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media ${breakpoint.maxMd} {
    margin: 0 auto;
  }
`;

export const StoreWrapper = styled.a`
  padding: 23px 49px 23px 30px;
  border-radius: 20px;
  background-color: #5c6566;
  display: grid;
  grid-template-columns: 37px auto;
  grid-column-gap: 30px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
  }
  img {
    width: 100%;
  }
  ${HeroCol} & ${Subheading}, ${HeroCol} & ${Heading} {
    color: #fff;
    text-align: left;
    margin-bottom: 0;
  }
`;

export const PhoneFrameWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 584px;
  margin: 1rem auto;
  position: relative;
  .frame {
    width: 100%;
    position: absolute;
  }
  .frame {
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 2;
  }
  .shadow {
    top: 0px;
    left: 0px;
    height: 110%;
    width: 110%;
  }
  .videoControls {
    position: absolute;
    z-index: 4;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    z-index: 3;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 20px 2px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      box-shadow: 0 0 0 3px rgba(212, 33, 44, 0.6);
    }
  }
`;

export const FrameContentWrapper = styled.div`
  position: absolute;
  left: 19px;
  top: 8px;
  z-index: 1;
  width: calc(100% - 38px);
  height: 566px;
  border-radius: 30px;
  overflow: hidden;
  video {
    border-radius: 30px;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    left: 15px;
    top: 8px;
    z-index: 3;
    width: calc(100% - 31px);
    border-radius: 30px;
  }
`;
