import styled, { keyframes } from 'styled-components';
import { breakpoint } from 'constants/Breakpoint';
import { Subheading, Heading } from 'components/.base/headings';

export const SlideNav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  left: 0;
  @media ${breakpoint.lg} {
    left: 40px;
  }
  .slideNav__inner {
    position: relative;
  }
`;

export const HeroCol = styled.div`
  width: 350px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media ${breakpoint.md} {
    margin: 0;
  }
`;

export const StoreWrapper = styled.a`
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
  padding: 23px 49px 23px 30px;
  border-radius: 20px;
  background-color: #5c6566;
  display: grid;
  grid-template-columns: 37px auto;
  grid-column-gap: 30px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
  }
  img {
    width: 100%;
  }
  ${Subheading}, ${Heading} {
    color: #fff;
    text-align: left;
    margin-bottom: 0;
  }
`;

export const PhoneFrameWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 584px;
  margin: 2rem auto 2rem;
  position: relative;
  svg {
    width: 100%;
    position: absolute;
  }
  .frame {
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 2;
  }
  .shadow {
    top: 0px;
    left: 0px;
    height: 110%;
    width: 110%;
  }
`;

export const FrameContentWrapper = styled.div`
  position: absolute;
  left: 19px;
  top: 10px;
  z-index: 1;
  height: calc(100% - 20px);
  width: calc(100% - 38px);
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
`;

export const fadeEffect = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
`;

export const SlideItem = styled.div`
  display: none;
  animation: ${fadeEffect} 1s;
  ${({ active }) =>
    active
      ? `
        display: block;
    `
      : ''}
`;
