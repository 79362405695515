import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { css } from 'styled-components';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { breakpoint } from 'constants/Breakpoint';

import { getImage } from 'helpers';
import { NarrowContainer, Row, Col } from 'components/.base/containers';
import { HeadingLarge, BodyText, Subheading, Heading } from 'components/.base/headings';
import Slideshow from 'components/.base/slideshow';

import PhoneFrame from '~/images/icons/homepage/phone_frame.svg';
import { WoodenBorder } from 'components/.base/border';

import s from './TextWithImage.module.scss';
import {
  FrameContentWrapper,
  HeroCol,
  PhoneFrameWrapper,
  SlideItem,
  SlideNav,
  StoreWrapper,
} from './TextWithImage.styled';
import cn from 'classnames';

const TextWithImage = ({ pageImages, sectionData }) => {
  gsap.registerPlugin(ScrollTrigger);

  const { appStoreLink, googlePlayLink, textWithImageBlock, title, backgroundImage } = sectionData;
  const [slideIndex, setSlideIndex] = useState(0);

  const $ref = useRef(null);

  useEffect(() => {
    if ($ref?.current) {
      let stepProgress = 1 / textWithImageBlock.length;
      let scrollTriggers = [];
      const buttons = document.querySelectorAll(`.${s.button}`);

      const st = ScrollTrigger.create({
        trigger: '.pinTrigger',
        start: 'top top',
        end: `top top-=${750 * textWithImageBlock.length}px`,
        pin: `.${s.pinElement}`,
        onUpdate: self => {
          const { progress, direction } = self;
          if (direction === 1) {
            // Scrolls down
            for (let i = 1; i >= 0; i = i - stepProgress) {
              if (progress > i) {
                setSlideIndex(i * textWithImageBlock.length);
                break;
              }
            }
          } else {
            // Scrolls up
            for (let i = 0; i <= 1; i = i + stepProgress) {
              if (progress < i) {
                setSlideIndex(i * textWithImageBlock.length - 1);
                break;
              }
            }
          }
        },
      });
      scrollTriggers.push(st);

      buttons.forEach(item => {
        item.addEventListener('click', e => {
          const slideTo = e.currentTarget.getAttribute('data-slide-to');
          let element = $ref.current;
          let toTop = 0;

          while (element.offsetParent.tagName !== 'BODY') {
            toTop += element.offsetTop;
            element = element.offsetParent;
          }

          toTop += element.offsetTop + 375;

          st.scroll(toTop + 750 * slideTo);
        });
      });

      // Specify how to clean up after this effect:
      return () => {
        // all garbage cleanup all goes in a single useEffect for when component leaves
        if (scrollTriggers) {
          scrollTriggers.map(st => {
            st.kill();
            return true;
          });
        }
      };
    }
  }, [textWithImageBlock.length]);

  const slideDots = textWithImageBlock.map((item, i) => {
    let x = i % 2 === 0 ? 15 : 23,
      y = 15 + i * 55;
    return {
      x,
      y,
    };
  });

  return (
    <div ref={$ref} className={cn(s.textWithImage, 'pinTrigger')}>
      <div className={s.pinElement}>
        <div className={s.overlay} />
        <Image className={s.bg} fluid={backgroundImage.fluid} alt="" aria-hidden="true" />
        <SlideNav>
          <div className="slideNav__inner">
            <svg width="41" height="314" viewBox="0 0 41 314" aria-hidden="true">
              <defs>
                <circle id="fh7846cooa" cx="15" cy="15" r="11" />
                <mask
                  id="n5xf2p67ef"
                  width="22"
                  height="22"
                  x="0"
                  y="0"
                  fill="#fff"
                  maskContentUnits="userSpaceOnUse"
                  maskUnits="objectBoundingBox"
                >
                  <use xlinkHref="#fh7846cooa" />
                </mask>
              </defs>
              <g fill="none" fillRule="evenodd">
                <g>
                  <path
                    stroke="#8B9A9C"
                    strokeDasharray="2 2"
                    strokeWidth="2"
                    d="M18.474 15C25.366 42.123 41 40 26 72c-8.35 17.815-24 29 0 80 7.065 15.013-15 43.5-4.994 71.39C31.61 252.948 29.941 277.485 16 297"
                  />
                </g>
              </g>
            </svg>
            {slideDots.map((slideDot, i) => (
              <button
                key={i}
                data-slide-to={i}
                aria-label={`Scroll to ${textWithImageBlock[i].title}`}
                className={`${s.button} bezierCurvesButton ${i === slideIndex ? s.active : ''}`}
                css={css`
                  left: ${slideDot.x - 13}px;
                  top: ${slideDot.y - 13}px;
                `}
              >
                <span className={`${s.button__iconWrapper}`}>
                  <span className={`${s.button__iconInner}`}></span>
                  <span className={`${s.button__iconDot}`}></span>
                </span>
              </button>
            ))}
          </div>
        </SlideNav>
        <NarrowContainer
          css={css`
            position: relative;
            z-index: 3;
          `}
        >
          <Row
            css={css`
              flex-wrap: wrap;
              @media ${breakpoint.maxMd} {
                display: none;
              }
            `}
          >
            <Col size="1">
              <HeroCol>
                <PhoneFrameWrapper>
                  <FrameContentWrapper>
                    {textWithImageBlock.map(({ mobilePreview }, i) => (
                      <SlideItem active={slideIndex === i} key={i}>
                        <Image fluid={mobilePreview.fluid} alt={mobilePreview.description} />
                      </SlideItem>
                    ))}
                  </FrameContentWrapper>
                  <PhoneFrame className="frame" />
                </PhoneFrameWrapper>
              </HeroCol>
            </Col>
            <Col size="1">
              <HeroCol>
                {textWithImageBlock.map(({ heading, description }, i) => (
                  <SlideItem active={slideIndex === i} key={i}>
                    <HeadingLarge
                      as="h2"
                      color="#fff"
                      css={css`
                        padding-top: 40px;
                        margin-bottom: 30px;
                      `}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: heading,
                        }}
                      ></span>
                    </HeadingLarge>
                    <BodyText
                      color="#fff"
                      css={css`
                        margin-bottom: auto;
                      `}
                    >
                      {description.description}
                    </BodyText>
                  </SlideItem>
                ))}
                <StoreWrapper
                  as={Link}
                  href={appStoreLink}
                  rel="noreferrer"
                  target="_blank"
                  css={css`
                    margin-top: 60px;
                    margin-bottom: 14px;
                    @media ${breakpoint.lg} {
                      margin-top: 130px;
                    }
                  `}
                >
                  <div>
                    <Image fluid={getImage(pageImages, 'apple-logo')} alt="App Store" />
                  </div>
                  <div>
                    <Subheading as="p">DOWNLOAD ON THE</Subheading>
                    <Heading as="p">App Store</Heading>
                  </div>
                </StoreWrapper>
                <StoreWrapper as={Link} href={googlePlayLink} rel="noreferrer" target="_blank">
                  <div>
                    <Image fluid={getImage(pageImages, 'play-store-logo')} alt="Play Store" />
                  </div>
                  <div>
                    <Subheading as="p">Get it on</Subheading>
                    <Heading as="p">Google Play</Heading>
                  </div>
                </StoreWrapper>
              </HeroCol>
            </Col>
          </Row>
        </NarrowContainer>
        <WoodenBorder elemenClass={s.border} top="100%" />
      </div>

      <div
        css={css`
          padding: 60px 20px;
          overflow: hidden;
          @media ${breakpoint.lg} {
            padding: 170px 20px;
            display: none;
          }
          .carousel {
            .control-dots {
              .circle .inner {
                background-color: rgb(255, 255, 255);
              }
            }
          }
          .slide {
            text-align: left;
          }
        `}
      >
        <Slideshow
          carouselLabel={title}
          arrowPosition={{
            top: 'auto',
            bottom: '5px',
            prev: {
              left: '20px',
              right: 'auto',
            },
            next: {
              left: '60px',
              right: 'auto',
            },
          }}
          dotPosition={{
            bottom: '5px',
            right: 'auto',
            left: '100',
            width: 'fit-content',
          }}
        >
          {textWithImageBlock.map((slide, i) => {
            return (
              <Row
                key={i}
                css={css`
                  flex-wrap: wrap;
                `}
              >
                <Col size="1">
                  <HeroCol>
                    <PhoneFrameWrapper>
                      <FrameContentWrapper>
                        <Image
                          fluid={slide.mobilePreview.fluid}
                          alt={slide.mobilePreview.description}
                        />
                      </FrameContentWrapper>
                      <PhoneFrame className="frame" />
                    </PhoneFrameWrapper>
                  </HeroCol>
                </Col>
                <Col
                  size="1"
                  css={css`
                    @media ${breakpoint.maxMd} {
                      order: -1;
                    }
                  `}
                >
                  <HeroCol>
                    <div>
                      <HeadingLarge
                        as="h2"
                        color="#fff"
                        css={css`
                          padding-top: 40px;
                          margin-bottom: 30px;
                        `}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: slide.heading,
                          }}
                        ></span>
                      </HeadingLarge>
                      <BodyText
                        color="#fff"
                        css={css`
                          margin-bottom: auto;
                        `}
                      >
                        {slide.description.description}
                      </BodyText>
                    </div>
                    <div
                      css={css`
                        display: none;
                        @media ${breakpoint.md} {
                          display: block;
                        }
                      `}
                    >
                      <StoreWrapper
                        as={Link}
                        href={appStoreLink}
                        rel="noreferrer"
                        target="_blank"
                        css={css`
                          margin-top: 60px;
                          margin-bottom: 14px;
                          @media ${breakpoint.lg} {
                            margin-top: 130px;
                          }
                        `}
                      >
                        <div>
                          <Image fluid={getImage(pageImages, 'apple-logo')} alt="App Store" />
                        </div>
                        <div>
                          <Subheading as="p">DOWNLOAD ON THE</Subheading>
                          <Heading as="p">App Store</Heading>
                        </div>
                      </StoreWrapper>
                      <StoreWrapper
                        as={Link}
                        href={googlePlayLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <div>
                          <Image fluid={getImage(pageImages, 'play-store-logo')} alt="Play Store" />
                        </div>
                        <div>
                          <Subheading as="p">Get it on</Subheading>
                          <Heading as="p">Google Play</Heading>
                        </div>
                      </StoreWrapper>
                    </div>
                  </HeroCol>
                </Col>
              </Row>
            );
          })}
        </Slideshow>
        <div
          css={css`
            @media ${breakpoint.md} {
              display: none;
            }
          `}
        >
          <StoreWrapper
            as={Link}
            href={appStoreLink}
            rel="noreferrer"
            target="_blank"
            css={css`
              margin-top: 60px;
              margin-bottom: 14px;
              @media ${breakpoint.lg} {
                margin-top: 130px;
              }
            `}
          >
            <div>
              <Image fluid={getImage(pageImages, 'apple-logo')} alt="App Store" />
            </div>
            <div>
              <Subheading as="p">DOWNLOAD ON THE</Subheading>
              <Heading as="p">App Store</Heading>
            </div>
          </StoreWrapper>
          <StoreWrapper as={Link} href={googlePlayLink} rel="noreferrer" target="_blank">
            <div>
              <Image fluid={getImage(pageImages, 'play-store-logo')} alt="Play Store" />
            </div>
            <div>
              <Subheading as="p">Get it on</Subheading>
              <Heading as="p">Google Play</Heading>
            </div>
          </StoreWrapper>
        </div>
        <div className={s.overlay} />
        <Image className={s.bg} fluid={backgroundImage.fluid} alt="" aria-hidden="true" />
      </div>
    </div>
  );
};

export default TextWithImage;
