import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Image from 'gatsby-image';
import { css } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { getImage } from '../../../helpers';
import { breakpoint } from '../../../constants/Breakpoint';

import Hero from '~/components/.base/hero';
import { NarrowContainer, Row, Col } from '~/components/.base/containers';
import { HeadingLarge, BodyText, Subheading, Heading } from '~/components/.base/headings';

import PhoneFrame from '~/images/icons/homepage/phone_frame.svg';
import PlayButton from '~/images/icons/mobileApp/play-btn.svg';
import PauseButton from '~/images/icons/mobileApp/pause-btn.svg';
import './HeroSection.scss';
import {
  HeroCol,
  StoreWrapper,
  PhoneFrameWrapper,
  FrameContentWrapper,
} from './HeroSection.styled';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const HeroSection = ({ pageImages, sectionData }) => {
  const { logoMobile } = useStaticQuery(graphql`
    {
      logoMobile: file(relativePath: { eq: "logos/logo_mobile.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed_withWebp
            aspectRatio
          }
        }
      }
    }
  `);
  const {
    appStoreLink,
    backgroundImage,
    description: { description },
    googlePlayLink,
    heading,
    mobilePreview,
  } = sectionData;

  const [isVideoPlaying, setIsVideoPlaying] = useState(null);
  const [canPlayVideo, setCanPlayVideo] = useState(false);

  const videoWrapperRef = useRef(null);

  const toggleVideoPlaying = useCallback(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      if (isVideoPlaying) {
        videoElm.pause();
        return;
      }
      if (canPlayVideo) {
        videoElm.play();
        return;
      }
    }
  }, [videoWrapperRef, isVideoPlaying, canPlayVideo]);

  useEffect(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      const handleOnPlay = () => {
        setIsVideoPlaying(true);
      };
      const handleOnPause = () => {
        setIsVideoPlaying(false);
      };
      const handleOnCanPlay = () => {
        setCanPlayVideo(true);
      };
      const handleOnLoadedData = () => {
        setCanPlayVideo(true);
      };
      videoElm.addEventListener('play', handleOnPlay);
      videoElm.addEventListener('pause', handleOnPause);
      videoElm.addEventListener('canplay', handleOnCanPlay);
      videoElm.addEventListener('loadeddata', handleOnLoadedData);
      return () => {
        videoElm.removeEventListener('play', handleOnPlay);
        videoElm.removeEventListener('pause', handleOnPause);
        videoElm.removeEventListener('canplay', handleOnCanPlay);
        videoElm.removeEventListener('loadeddata', handleOnLoadedData);
      };
    }
  }, [videoWrapperRef, setIsVideoPlaying, setCanPlayVideo]);

  return (
    <Hero size="full" imageFluid={backgroundImage.fluid} wrapperClass="hero--mobileApp" alt="">
      <div
        className="hero-details"
        css={css`
          padding: 0 20px;
          ${NarrowContainer} {
            ${HeadingLarge}, ${BodyText} {
              text-align: left;
            }
          }
        `}
      >
        <NarrowContainer>
          <Row
            css={css`
              @media ${breakpoint.maxMd} {
                flex-direction: column;
              }
            `}
          >
            <Col size="1">
              <HeroCol>
                <HeadingLarge
                  css={css`
                    padding-top: 40px;
                  `}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: heading,
                    }}
                  ></span>
                </HeadingLarge>
                <BodyText
                  css={css`
                    margin-bottom: auto;
                  `}
                >
                  {description}
                </BodyText>
                <StoreWrapper
                  as={Link}
                  className="button"
                  href={appStoreLink}
                  css={css`
                    margin-top: 30px;
                    margin-bottom: 14px;
                    @media ${breakpoint.lg} {
                      margin-top: 130px;
                    }
                  `}
                >
                  <div>
                    <Image fluid={getImage(pageImages, 'apple-logo')} alt="App Store" />
                  </div>
                  <div>
                    <Subheading as="p">DOWNLOAD ON THE</Subheading>
                    <Heading as="p">App Store</Heading>
                  </div>
                </StoreWrapper>
                <StoreWrapper as={Link} className="button" href={googlePlayLink}>
                  <div>
                    <Image fluid={getImage(pageImages, 'play-store-logo')} alt="Play Store" />
                  </div>
                  <div>
                    <Subheading as="p">Get it on</Subheading>
                    <Heading as="p">Google Play</Heading>
                  </div>
                </StoreWrapper>
              </HeroCol>
            </Col>
            <Col>
              <HeroCol>
                <PhoneFrameWrapper>
                  {mobilePreview.file.contentType === 'video/mp4' && (
                    <button
                      onClick={toggleVideoPlaying}
                      className={`videoControls ${isVideoPlaying ? 'pause' : 'play'}`}
                    >
                      <span className="sr-only">
                        {isVideoPlaying ? 'Pause video' : 'Play video'}
                      </span>
                      {isVideoPlaying ? <PauseButton /> : <PlayButton />}
                    </button>
                  )}
                  {mobilePreview.file.contentType === 'video/mp4' ? (
                    <FrameContentWrapper
                      ref={videoWrapperRef}
                      dangerouslySetInnerHTML={{
                        __html: `<video
                          width="100%"
                          height="auto"
                          preload="metadata"
                          muted
                          loop
                          playsinline
                          disablePictureInPicture
                        >
                          <source
                            src="${mobilePreview.file.url}"
                            type="video/mp4"
                          />
                          Sorry, your browser does not support embedded videos.
                        </video>`,
                      }}
                    />
                  ) : (
                    <FrameContentWrapper>
                      <Image fluid={mobilePreview.fluid} alt={mobilePreview.description} />
                    </FrameContentWrapper>
                  )}
                  <PhoneFrame className="frame" />
                  <div className="shadow-container">
                    <Image fixed={logoMobile.childImageSharp.fixed} alt="" />
                  </div>
                </PhoneFrameWrapper>
              </HeroCol>
            </Col>
          </Row>
        </NarrowContainer>
      </div>
    </Hero>
  );
};

export default HeroSection;
