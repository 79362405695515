import React from 'react';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { chunk } from 'lodash';

import { Row, Col, CenteredContainer, FlexBox, Container } from '../../.base/containers';
import { Heading } from '../../.base/headings';
import Slideshow from '../../.base/slideshow';
import { breakpoint } from '../../../constants/Breakpoint';

const LogoItem = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    max-width: 33.333333%;
    .gatsby-image-wrapper {
        width: 120px;
        max-width: 100%;
    }
    @media ${breakpoint.lg} {
        max-width: 20%;
        padding: 30px;
    }
    @media ${breakpoint.xl} {
        padding: 50px;
    }
    &:not(:last-child) {
        border-right: 1px solid #e1e4e4;
    }
`;

const AsSeenIn = ({ sectionData }) => {
    const logoBlockGroup = chunk(sectionData.logoBlock, 5);
    const logoBlockGroupMob = chunk(sectionData.logoBlock, 3);
    console.log(sectionData);

    return (
        <Container>
            <Row
                css={css`
                    padding: 60px 0;
                    overflow: hidden;
                    .carousel .slide img {
                        width: 100px;
                        max-width: 100%;
                    }
                    @media ${breakpoint.maxSm} {
                        .carousel {
                            .prev {
                                left: 0;
                            }
                            .next {
                                left: 40px;
                            }
                            .control-dots {
                                left: 80px;
                            }
                        }
                    }
                `}
            >
                <Col size={4}>
                    <CenteredContainer
                        css={css`
                            .carousel-root .carousel.carousel-slider {
                                overflow: visible;
                            }
                        `}
                    >
                        <Heading
                            css={css`
                                padding: 0 20px;
                                @media ${breakpoint.lg} {
                                    padding: 0 2.5rem;
                                }
                                @media ${breakpoint.maxLg} {
                                    align-self: flex-start;
                                }
                            `}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: sectionData.heading,
                                }}
                            ></span>
                        </Heading>
                        <div
                            css={css`
                                margin: 25px 0 60px;
                                padding: 0 20px;
                                max-width: 100%;
                                @media ${breakpoint.lg} {
                                    padding: 0 2.5rem;
                                }
                                .carousel .control-dots .circle .inner {
                                    background-color: rgba(92, 101, 102, 0.7);
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: none;
                                    @media ${breakpoint.lg} {
                                        display: block;
                                    }
                                `}
                            >
                                <Slideshow
                                    carouselLabel={sectionData.title}
                                    arrowPosition={{
                                        top: 'auto',
                                        bottom: '-60px',
                                        prev: {
                                            right: 'calc(50% + 100px)',
                                            left: 'auto',
                                        },
                                        next: {
                                            right: 'calc(50% + 60px)',
                                            left: 'auto',
                                        },
                                    }}
                                    dotPosition={{
                                        top: 'auto',
                                        bottom: '-60px',
                                        right: 'auto',
                                        left: 'calc(50% - 20px)',
                                        width: 'fit-content',
                                    }}
                                >
                                    {logoBlockGroup.map((blocks, i) => (
                                        <FlexBox
                                            css={css`
                                                display: flex;
                                                border: 1px solid #e1e4e4;
                                                border-radius: 30px;
                                            `}
                                            key={i}
                                        >
                                            {blocks.map((block, j) => (
                                                <LogoItem key={j}>
                                                    <Image
                                                        fluid={block.image.fluid}
                                                        alt={block.image.description}
                                                    />
                                                </LogoItem>
                                            ))}
                                        </FlexBox>
                                    ))}
                                </Slideshow>
                            </div>
                            <div
                                css={css`
                                    @media ${breakpoint.lg} {
                                        display: none;
                                    }
                                `}
                            >
                                <Slideshow
                                    carouselLabel={sectionData.title}
                                    arrowPosition={{
                                        top: 'auto',
                                        bottom: '-60px',
                                        prev: {
                                            right: 'calc(50% + 100px)',
                                            left: 'auto',
                                        },
                                        next: {
                                            right: 'calc(50% + 60px)',
                                            left: 'auto',
                                        },
                                    }}
                                    dotPosition={{
                                        top: 'auto',
                                        bottom: '-60px',
                                        right: 'auto',
                                        left: 'calc(50% - 20px)',
                                        width: 'fit-content',
                                    }}
                                >
                                    {logoBlockGroupMob.map((blocks, i) => (
                                        <FlexBox
                                            css={css`
                                                display: flex;
                                                width: auto;
                                                border: 1px solid #e1e4e4;
                                                border-radius: 30px;
                                            `}
                                            key={i}
                                        >
                                            {blocks.map((block, j) => (
                                                <LogoItem key={j}>
                                                    <Image
                                                        fluid={block.image.fluid}
                                                        alt={block.image.description}
                                                    />
                                                </LogoItem>
                                            ))}
                                        </FlexBox>
                                    ))}
                                </Slideshow>
                            </div>
                        </div>
                    </CenteredContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default AsSeenIn;
