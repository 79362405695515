import React from 'react';
import styled, { css } from 'styled-components';

import { Row, Col } from 'components/.base/containers';
import { Heading, BodyText } from 'components/.base/headings';

import { Collapsible, CollapsibleInner } from 'components/.base/Collapsible';
import { breakpoint } from '../../../constants/Breakpoint';

const CntWrapper = styled.div`
    position: relative;
    max-width: 515px;
    margin: 0 auto;
    ${CollapsibleInner} {
        max-width: 400px;
    }
    ${BodyText} {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.2px;
    }
`;

const Faq = ({ sectionData }) => {
    return (
        <Row
            css={css`
                padding: 60px 20px;
                position: relative;
                @media ${breakpoint.lg} {
                    padding: 160px 20px 160px;
                }
            `}
        >
            <Col size={4}>
                <CntWrapper>
                    <Heading
                        css={css`
                            margin-bottom: 60px;
                        `}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: sectionData.heading,
                            }}
                        ></span>
                    </Heading>
                    {sectionData.faqSectionFaqBlock.map((item, i) => (
                        <Collapsible
                            key={i}
                            open={i === 0 ? true : false}
                            heading={item.question.question}
                            description={item.answer.answer}
                        />
                    ))}
                </CntWrapper>
            </Col>
        </Row>
    );
};

export default Faq;
